header {
  display: inline-block;
  width: 100%;
  padding: 4px 1%;
  max-width: 1600px;
  
  .logo-container {
    float: left;

    img {
      width: 100px;
      float: left;
    }
  }

  #mb-menu-opener {
    position: fixed;
    right: 12px;
    top: 12px;
    width: 43px;
    height: 43px;
    background-color: #fff;
    z-index: 99;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

    div{
      transition: 0.2s;
    }

    .mblp-f {
      position: absolute;
      top: 10px;
      left: 4px;
      width: 36px;
      height: 3px;
      background-color: #999;;
    }
    .mblp-s {
      position: absolute;
      top: 20px;
      left: 4px;
      width: 36px;
      height: 3px;
      background-color: #999;;
    }
    .mblp-t {
      position: absolute;
      top: 30px;
      left: 4px;
      width: 36px;
      height: 3px;
      background-color: #999;;
    }

    &.open {
      .mblp-f {
        top: 20px;
        transform: rotate(-45deg);
        transform-origin: 50% 50%;
      }
      .mblp-t {
        top: 20px;
        transform: rotate(45deg);
        transform-origin: 50% 50%;
      }
      .mblp-s {
        opacity: 0;
      }
    }
  }

  #header-nav-menu {
    margin: 60px 120px 20px;
    text-align: center;
    text-align: left;

    a {
      line-height: 44px;
      height: 44px;
      display: inline-block;
      margin: 0 20px;
    }

    @media (max-width: 1024px) {
      position: fixed;
      right: -100%;
      top: 0;
      background-color: #f9f9f9;
      width: 100%;
      height: 100vh;
      margin: 0;
      z-index: 89;
      padding-top: 80px;
      transition: 0.3s;
      overflow: hidden;

      a {
        //margin-bottom: 12px;
        width: 100%;
        height: auto;
        line-height: normal;
        margin: 6px 0;
        padding: 6px 0 6px 20px;
      }
    }

    &.mobile-open {
      right: 0;
    }
  }
}
.main {
  display: inline-block;
  width: 100%;
  padding: 0  2%;
  min-height: 80vh;
}