#page-container-contact {
  text-align: center;
  max-width: 96vw;

  .page-exp {
    margin: 20px 0 50px;
  }

  .contact-form {
    font-size: 0;
    text-align: left;
    text-align: center;
    
    #contact-page-form {
      font-size: 16px;
      font-family: 'IBM Plex Sans', Helvetica, Verdana, Geneva, Tahoma, sans-serif;
      text-align: left;
      display: inline-block;
      max-width: 100%;

      .cf-row {
        margin-bottom: 20px;

        & * {
          font-family: 'IBM Plex Sans', Helvetica, Verdana, Geneva, Tahoma, sans-serif;
          font-size: 16px;
        }

        .cf-label {
          display: block;
        }

        .cf-text {
          padding: 6px;
          width: 600px;
          max-width: 100%;
          font-weight: bold;
        }
        .cf-mtext {
          display: block;
          padding: 6px;
          width: 600px;
          max-width: 100%;
          height: 200px;
          max-width: 100%;
          font-weight: bold;
        }

        .cf-submit {
          display: block;
          border: 3px solid #a4a4a4;
          padding: 4px 12px;
          color: #fff;
          background-color: #a4a4a4;
          cursor: pointer;
          font-size: 20px;

          &:hover {
            color: #a4a4a4;
            background-color: #fff;
          }
        }

        .fsuberr {
          padding: 6px;
          margin: 2px 0;
          background-color: #5d2222;
          color: #fff;
          font-weight: bold;
        }
      }
    }
  }
}