footer {
  display: inline-block;
  width: 100%;
  padding: 0 2%;

  .footer-content {
    border-top: 1px solid #e1e1e1;
    padding: 30px 0;
    text-align: left;

    .footer-link {
      color: #333;
      display: block;
      clear: both;
      font-size: 15px;
      margin: 4px 0 8px;

      &:hover {
        color: #111;
      }
    }

    .cpyrttxt {
      color: #333;
      font-size: 13px;
      display: block;
      text-align: center;
    }
  }
}