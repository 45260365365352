.homepage {
  margin-bottom: 100px;

  .video-icon {
    width: 600px;
    display: inline-block;
    height: 400px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    margin: 0 2% 30px;
    max-width: 96%;

      .linkTxt {
        padding-top: 24px;
        display: inline-block;
      }
  }
}