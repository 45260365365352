.privacy-page {

  h1 {
    font-size: 33px;
  }
  h2 {
    font-size: 22px;
    margin: 30px 0 12px;
  }
  h3 {
    font-size: 19px;
    margin: 24px 0 8px;
  }
  p {
    margin: 8px 0;
    font-size: 17px;
  }
  a {
    font-weight: bold;
  }
  .mail {
    color: #1c0857;
    font-weight: bolder;
  }
  ul {
    list-style-type: none;

    li {
      margin-left: 20px;
      margin-bottom: 12px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: -12px;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        background-color: #c1c1c1;
      }
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-width: 1px;
    border-color: #c4c4c4;
    border-style: solid;
    margin: 20px 0;
    font-size: 17px;

    thead {
      background-color: #f5f5f5;

      th {
        border-width: 1px;
        border-color: #c4c4c4;
        border-style: solid;
        padding: 6px;
      }
    }

    td{
      border-width: 1px;
      border-color: #c4c4c4;
      border-style: solid;
      padding: 5px;
    }
    
  }
}