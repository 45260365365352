body {
  color: #333;
  font-family: 'IBM Plex Sans', Helvetica, Verdana, Geneva, Tahoma, sans-serif;
    font-size: 19px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}
#root {
  text-align: center;
  margin: 0 auto;
  max-width: 1920px;
}

.page-content {
  margin: 40px 0;
  text-align: left;
}

.page-title {
  margin-top: 60px;
  margin-bottom: 30px;
  display: block;
}